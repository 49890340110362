import React from 'react';
 const ContactsInfo = ({selectedMark, show, setShow}) => {
     return (
         <div className={` contacts__block ${ show && 'contacts__block--active' }`}>
             <div className='contacts__block-wrapper'>
                 <div className='contacts__forms__toggle contacts__forms__toggle--block' onClick={() =>setShow(prev => !prev)}>
                     Показати контакти
                 </div>
                 <h1 className={'contacts__block_head'}>Контактна інформація</h1>
                 <div className={'contacts__block_addresses'}>
                     <h3 className={'contacts__block_headers'}>АДРЕСА</h3>
                     <p className={'contacts__block_text'}>{selectedMark.address1}</p>
                     <p className={'contacts__block_text'}>{selectedMark.address2}</p>
                     <a className={'contacts__block_link'} target='_blank'
                        href="https://www.google.com/maps/dir//%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%90%D0%B2%D1%82%D0%BE%D0%BC%D0%BE%D0%B1%D1%96%D0%BB%D1%96%D1%81%D1%82%D1%96%D0%B2,+22,+%D0%9C%D1%83%D0%BA%D0%B0%D1%87%D0%B5%D0%B2%D0%BE,+%D0%97%D0%B0%D0%BA%D0%B0%D1%80%D0%BF%D0%B0%D1%82%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+89611/@48.4462025,22.6829592,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x4739aba0da35fad3:0x446564f89c6c0462!2m2!1d22.6855395!2d48.4462025!3e0?hl=uk-UK">Прокласти
                         маршрут ></a>
                 </div>
                 <div className={'contacts__block_phones'}>
                     <h3 className={'contacts__block_headers'}>ТЕЛЕФОНИ:</h3>
                     <div className={'contacts__block_text'}>+380 66 992 91 11</div>
                     <div className={'contacts__block_text'}>+380 68 992 91 11</div>
                 </div>
                 <div className={'contacts__block_schedule'}>
                     <h3 className={'contacts__block_headers'}>ГРАФІК РОБОТИ:</h3>
                     <div className={'contacts__block_text'}>Графік роботи шоу-рума:</div>
                     <div className={'contacts__block_text'}>Пн-Пт: з 9:00 до 17:30</div>
                     <div className={'contacts__block_text'}>Сб-Нд: вихідний</div>
                 </div>
                 <div className={'contacts__block_email'}>
                     <h3 className={'contacts__block_headers'}>E-MAIL:</h3>
                     <div>
                         <a className={'contacts__block_text'} href="mailto:lika@gmail.com">sell@lika.net.ua</a>
                     </div>
                 </div>
             </div>
         </div>
     )
 }


export default ContactsInfo;
