import React, {useState} from 'react'
import {connect} from 'react-redux'
import {
    toggleModal,
    toggleQuiz,
} from "../../../../../redux/actionCreators/modalsCreator";
import MobileMenuServices from "./MobileMenuServices";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";

const MobileMenuBody = props => {

    const history = useHistory()

    const [mobileMenuSubcategoryService, setMobileMenuSubcategoryService] = useState(false)

    const openMobileSubMenuService = () => {
        return (
            setMobileMenuSubcategoryService(!mobileMenuSubcategoryService)
        )
    }

    const services1 = [
        {name: 'Монтаж кондиціонерів',link: '/services_page/montazh_kondytsioneriv',},
        {name: 'Монтаж вентиляції',link: '/services_page/montazh_ventyliatsii',},
        {name: 'Монтаж теплового обладнання',link: '/services_page/montazh_teplovoho_obladnannia',},
        {name: 'Монтаж центральних пилососів',link: '/services_page/montazh_tsentralnykh_pylososiv',},
        {name: 'Проектування систем',link: '/services_page/proektuvannia_system',},
        {name: 'Сервісне обслуговування',link: '/services_page/servisne_obsluhovuvannia',},
    ]

    return (
        <ul className="mobile-menu__body">
            <li className="mobile-menu__item mobile-menu__item--highlight"
                onClick={() => {
                    props.openMobileMenu()}}
            >
                <Link to="/catalog_discount">Акції</Link>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/catalog/82-rozprodaz')
                props.setMobileMenuCategory(false)
            }}>
                <div>Розпродаж</div>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/kondiciyuvannya-optom')
                props.setMobileMenuCategory(false)
            }}>
                <div>Гурт</div>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                window.open("https://www.likaimv.net.ua", "_blank");
                props.setMobileMenuCategory(false)
            }}>
                <div>Виїзд спеціаліста</div>
            </li>

            <li className="mobile-menu__item mobile-menu__item--lv1" onClick={openMobileSubMenuService}>
                Послуги
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  d="M8.15421 14.9962C8.05339 14.9456 7.97676 14.857 7.94113 14.7499C7.90549 14.6429 7.91377 14.5261 7.96413 14.4251L10.4249 9.501L7.96328 4.57775C7.93769 4.52766 7.92227 4.473 7.91791 4.41693C7.91355 4.36085 7.92033 4.30447 7.93786 4.25102C7.95539 4.19758 7.98333 4.14814 8.02006 4.10554C8.05679 4.06295 8.10159 4.02804 8.15187 4.00285C8.20216 3.97765 8.25693 3.96265 8.31304 3.95872C8.36915 3.95479 8.42548 3.96201 8.47878 3.97996C8.53209 3.9979 8.58131 4.02622 8.62362 4.06328C8.66593 4.10034 8.70049 4.14541 8.72529 4.19589L11.2824 9.31007C11.3121 9.36934 11.3275 9.43471 11.3275 9.501C11.3275 9.56729 11.3121 9.63267 11.2824 9.69193L8.72529 14.8061C8.67465 14.9069 8.5861 14.9836 8.47904 15.0192C8.37199 15.0548 8.25517 15.0466 8.15421 14.9962Z" fill="#182316"/>
                </svg>
                <ul className={`mobile-menu__lv2-body ${mobileMenuSubcategoryService? 'active' : ''}`}>
                    <div className="mobile-menu__lv2-head">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path  d="M13.6999 5.05661C13.8273 5.12058 13.924 5.23244 13.9691 5.36767C14.0141 5.50289 14.0036 5.65045 13.94 5.77798L10.8316 11.9979L13.9411 18.2168C13.9734 18.28 13.9929 18.3491 13.9984 18.4199C14.0039 18.4907 13.9953 18.562 13.9732 18.6295C13.951 18.697 13.9157 18.7594 13.8693 18.8132C13.823 18.867 13.7664 18.9111 13.7028 18.943C13.6393 18.9748 13.5701 18.9937 13.4993 18.9987C13.4284 19.0036 13.3572 18.9945 13.2899 18.9719C13.2226 18.9492 13.1604 18.9134 13.107 18.8666C13.0535 18.8198 13.0099 18.7629 12.9785 18.6991L9.74851 12.2391C9.711 12.1642 9.69147 12.0816 9.69147 11.9979C9.69147 11.9142 9.711 11.8316 9.74851 11.7567L12.9785 5.29671C13.0425 5.16935 13.1544 5.07256 13.2896 5.02755C13.4248 4.98254 13.5724 4.99299 13.6999 5.05661Z" fill="white"/>
                        </svg>
                        <p className="mobile-menu__lv2-head-title">Послуги</p>
                    </div>
                    <MobileMenuServices services={services1} setMobileMenuCategory={props.setMobileMenuCategory}/>
                </ul>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/blog')
                props.setMobileMenuCategory(false)
            }}>
                <div>Блог</div>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/cases')
                props.setMobileMenuCategory(false)
            }}>
                <div>Наші роботи</div>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/delivery_payment')
                props.setMobileMenuCategory(false)
            }}>
                <div>Доставка та оплата</div>
            </li>

            <li className="mobile-menu__item" onClick={() => {
                history.push('/about_us')
                props.setMobileMenuCategory(false)
            }}>
                <div>Про нас</div>
            </li>
        </ul>
    )
}

const mpaDispatchToProps = {
    toggleModal,
    toggleQuiz,
}

export default connect(null, mpaDispatchToProps)(MobileMenuBody)