import React, { useState } from "react";
import img from "../../img/PhoneContacts.svg";
import img2 from "../../img/ClockContacts.svg";
import axios from "axios";
import {
  toggleFeedbackSuccessModal,
  toggleModal,
} from "../../redux/actionCreators/modalsCreator";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { _api } from "../../API";

const checkPhone = (value) => {
  const clearTel = (tel) => tel.replace(/[^0-9]/g, "");
  const clearedTel = clearTel(value);
  if(clearedTel.length < 12) return false;
  else return true;
};

const ContactsForm = (props) => {
  const [showList, setShowList] = useState(false);
  const [showListAdresses, setShowListAdresses] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: false,
    phone: false,
    city: false,
    building: false,
  });

  const [loadingsendData, setLoadingsendData] = useState(false);

  const [inputError, setInputError] = useState(false);

  const sendData = () => {
    if (checkErrors()) {
      const address = `
        ${
          props.street.includes("вул.") ? props.street : `вул. ${props.street}`
        }, дім ${props?.home || "не вказано"}, кв. ${
        props.apartmantValue.length === 0 ? "не вказана" : props.apartmantValue
      }`;

      setLoadingsendData(() => true);
      axios
        .post(`${_api}/spec_request`, {
          name: props.name,
          phone: checkPhone(props.phone) ? props.phone : null,
          city: props.city.Description ? props.city.Description : props.city,
          street: props?.street || "не вказано",
          building: props?.home || "не вказано",
          apartment:
            props.apartmantValue.length === 0
              ? "Квартира не вказана"
              : props.apartmantValue,
        })
        .then((res) => {
          props.setStreet("");
          props.setHome("");
          props.setApartmantValue("");
          props.toggleModal(true);
          props.toggleFeedbackSuccessModal(true);
        })
        .finally(() => setLoadingsendData(() => false));
    }
  };
  const checkErrors = function () {
    if (props.name.length < 2) {
      setFormErrors((prev) => ({ ...prev, name: true }));
    } else {
      setFormErrors((prev) => ({ ...prev, name: false }));
    }
    if (checkPhone(props.phone)) {
      setFormErrors((prev) => ({ ...prev, phone: false }));
    } else {
      setFormErrors((prev) => ({ ...prev, phone: true }));
    }
    if (typeof props.city === "string" && props.city.length < 4) {
      setFormErrors((prev) => ({ ...prev, city: true }));
    } else if (props.city.Description && props.city.Description.length < 4) {
      setFormErrors((prev) => ({ ...prev, city: true }));
    } else {
      setFormErrors((prev) => ({ ...prev, city: false }));
    }

    return !Object.values(formErrors).includes(true);
  };

  const cityListFilter = props.cityList.filter(
    (el) => el.AreaDescriptionRu === "Закарпатская"
  );

  return (
    <div
      className={`contacts__forms ${
        props.show ? "contacts__forms--active" : "contacts__forms"
      }`}
    >
      <div className={"contacts__forms--padding"}>
        <h1 className={"contacts__block_head"}>
          Замовлення виїзду спеціаліста
        </h1>
        <div className={"contacts__forms_info"}>
          <div className={"contacts__block_phones"}>
            <div className={"contacts__forms_ico"}>
              <img src={img} alt="" />
              <h3 className={"contacts__block_headers"}>ТЕЛЕФОНИ:</h3>
            </div>
            <div className={"contacts__phones-wrapper"}>
              <div>
                <a className={"contacts__block_text"} href="tel:+380669929111">
                  +380 66 992 91 11
                </a>
              </div>
              <div>
                <a className={"contacts__block_text"} href="tel:+380689929111">
                  +380 68 992 91 11
                </a>
              </div>
            </div>
          </div>
          <div className={"contacts__block_schedule"}>
            <div className={"contacts__forms_ico"}>
              <img src={img2} alt="" />
              <h3 className={"contacts__block_headers"}>ГРАФІК РОБОТИ:</h3>
            </div>
            <div className={"contacts__phones-wrapper"}>
              <div className={"contacts__block_text"}>Графік роботи шоу-рума:</div>
              <div className={"contacts__block_text"}>Пн-Пт: з 9:00 до 17:30</div>
              <div className={"contacts__block_text"}>Сб-Нд: вихідний</div>
            </div>
          </div>
        </div>
      </div>
      <div className={"contacts__form"}>
        <form
          className={"contacts__form__content"}
          onSubmit={(e) => e.preventDefault()}
        >
          <h2 className={"contacts__form__head"}>
            Бажаєте замовити виїзд спеціаліста? Напишіть нам!
          </h2>
          <input
            className={`contacts__form__input ${
              formErrors.name && "input-error"
            }`}
            type="text"
            placeholder={"Ім’я*"}
            value={props.name}
            onChange={(e) => {
              props.setName(e.target.value);
              setFormErrors((prev) => ({ ...prev, name: false }));
            }}
          />
          <InputMask
            mask="+38 (099) 999-99-99"
            style={{ borderColor: inputError && "red" }}
            value={props.phone}
            onChange={(e) => {
              props.setPhone(e.target.value);
            }}
            className={`contacts__form__input ${
              formErrors.phone && "input-error"
            }`}
            placeholder="+38 (0__) ___-__-__*"
          />
          <div className={"contacts__form__input__wrapper"}>
            <input
              className={`contacts__form__input ${
                formErrors.city && "input-error"
              }`}
              type="text"
              placeholder={"Місто*"}
              value={props.city.Description || props.city}
              onFocus={() => setShowList(true)}
              onChange={(e) => {
                props.setCity(e.target.value);
                setInputError(false);
              }}
            />
            {cityListFilter.length > 0 && (
              <div
                className={`${
                  showList && props.cityList.length !== 0
                    ? "contacts__dropdown-selector"
                    : "contacts__dropdown-selector--hidden"
                }`}
              >
                {cityListFilter.map((el) => (
                  <div
                    key={el.Ref}
                    onClick={() => {
                      props.setCity(el);
                      setShowList(false);
                    }}
                  >
                    {el.SettlementTypeDescription.slice(0, 1)}. {el.Description}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={"contacts__form__streetAndHome"}>
            <div className={"  contacts__form__streetAndHome--lg"}>
              <input
                className="contacts__form__input contacts__form__streetAndHome--lg"
                type="text"
                placeholder={"Вулиця"}
                value={props.street}
                onFocus={() => setShowListAdresses(true)}
                onChange={(e) => {
                  props.setStreet(e.target.value);
                }}
              />
              <div
                className={
                  props.adressList[0]?.Addresses.length && showListAdresses
                    ? "contacts__dropdown-selector"
                    : "contacts__dropdown-selector--hidden"
                }
              >
                {props.adressList[0] &&
                  props.adressList[0].Addresses.map((el) => (
                    <div
                      key={el.Ref}
                      onClick={() => {
                        props.setStreet(el.Present);
                        setShowListAdresses(false);
                      }}
                      className={"street__text"}
                    >
                      {el.Present}
                    </div>
                  ))}
              </div>
            </div>
            <div className={" contacts__form__streetAndHome--md"}>
              <input
                className={`contacts__form__input contacts__form__streetAndHome--md ${
                  formErrors.building && "input-error"
                }`}
                type="text"
                placeholder={"Будинок"}
                value={props.home}
                onChange={(e) => {
                  props.setHome(e.target.value);
                  setFormErrors((prev) => ({ ...prev, building: false }));
                }}
              />
            </div>
            <div className={" contacts__form__streetAndHome--sm"}>
              <input
                className={`contacts__form__input contacts__form__streetAndHome--sm`}
                type="text"
                placeholder={"Квартира"}
                value={props.apartmantValue}
                onChange={(e) => {
                  props.setApartmantValue(e.target.value);
                  setFormErrors((prev) => ({ ...prev, apartment: false }));
                }}
              />
            </div>
          </div>
          <button
            className={"btn-light contacts__form__btn"}
            onClick={() => sendData()}
            disabled={loadingsendData}
          >
            Надіслати
          </button>
        </form>
      </div>
      <div
        className={` contacts__forms__toggle`}
        onClick={() => props.setShow((prev) => !prev)}
      >
        Заповнити форму
      </div>
    </div>
  );
};

const mapStateToProps = {
  toggleModal,
  toggleFeedbackSuccessModal,
};

export default connect(() => {}, mapStateToProps)(ContactsForm);
