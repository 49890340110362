import React from 'react'

const FooterItemsInfoPhone = (props) => {

    return (
        <>
            {
                props.phones.map((data, i) => {
                    return (
                        <span className="footer__contacts-phone">{data}</span>
                    )
                })
            }
        </>
    )
}

export default FooterItemsInfoPhone