import React from "react";
import { connect } from "react-redux";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import masterCard from "../../img/mastercard.svg";
import visa from "../../img/visa.svg";
import FooterListItems from "./FooterlistItems";
import FooterItemsInfoWork from "./FooterItemsInfoWork";
import FooterItemsInfoAddress from "./FooterItemsInfoAddress";
import "./Footer.scss";
import FooterItemsInfoPhone from "./FooterItemsInfoPhone";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const newDate = new Date();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const first = [
    { name: "Доставка та оплата", link: "/delivery_payment" },
    { name: "Обмін та повернення", link: "/services_page/exchange" },
    {
      name: "Сервісне обслуговування",
      link: "/services_page/servisne_obsluhovuvannia",
    },
  ];
  const second = [
    { name: "Про нас", link: "/about_us" },
    // {name: 'Бонусна програма', link: '/'},
    { name: "Договір публічної оферти", link: "/offer" },
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__body">
          <div className="footer__logo-wrap">
            <Link to="/">
              <img className="header__logo" src={logo} alt="Логотип" />
            </Link>
          </div>
          <div className="footer__content-wrap">
            <div className="footer__link-section footer__link-section--first">
              <FooterListItems items={first} />
            </div>
            <div className="footer__link-section footer__link-section--second">
              <FooterListItems items={second} />
            </div>
            <div className="footer__schedule">
              <div className="footer__section-title">Графік роботи шоу-рума</div>
              <FooterItemsInfoWork workTime={props.workTime} />
              <FooterItemsInfoAddress address={props.address} city={props.city} />
            </div>
            <div className="footer__contacts">
              <div className="footer__phones">
                <div className="footer__section-title">Контакти</div>
                <FooterItemsInfoPhone phones={props.phones} />
              </div>
              <div className="footer__phones">
                <div className="footer__section-title">Сервісний центр:</div>
                <span className="footer__contacts-phone">+38 050 372 46 42</span>
              </div>
              <div className="footer__social">
                <a
                  className="footer__social-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.tiktok.com/@lika_comfort"
                >
                  <span style={{ display: "none" }}>TikTok</span>
                  <svg fill="#43B02A" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="28px" height="28px">
                    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
                  </svg>
                </a>
                <a
                  className="footer__social-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/lika_comfort/?igshid=1qxag6rjd3m53"
                >
                  <span style={{ display: "none" }}>instagram</span>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.9294 11.0733C27.916 10.0634 27.7269 9.06364 27.3708 8.11861C27.0619 7.32148 26.5901 6.59754 25.9857 5.99305C25.3812 5.38856 24.6572 4.91681 23.8601 4.60794C22.9272 4.25775 21.9417 4.0684 20.9454 4.04794C19.6628 3.99061 19.2561 3.97461 16.0001 3.97461C12.7441 3.97461 12.3268 3.97461 11.0534 4.04794C10.0576 4.06855 9.07256 4.2579 8.14009 4.60794C7.34284 4.9166 6.61879 5.38827 6.01427 5.99279C5.40975 6.5973 4.93808 7.32135 4.62943 8.11861C4.27854 9.05079 4.08959 10.0361 4.07076 11.0319C4.01343 12.3159 3.99609 12.7226 3.99609 15.9786C3.99609 19.2346 3.99609 19.6506 4.07076 20.9253C4.09076 21.9226 4.27876 22.9066 4.62943 23.8413C4.9386 24.6383 5.41062 25.362 6.01534 25.9663C6.62006 26.5706 7.34418 27.042 8.14143 27.3506C9.07134 27.7149 10.0566 27.9178 11.0548 27.9506C12.3388 28.0079 12.7454 28.0253 16.0014 28.0253C19.2574 28.0253 19.6748 28.0253 20.9481 27.9506C21.9443 27.931 22.9299 27.7421 23.8628 27.3919C24.6597 27.0827 25.3834 26.6108 25.9879 26.0064C26.5923 25.402 27.0642 24.6782 27.3734 23.8813C27.7241 22.9479 27.9121 21.9639 27.9321 20.9653C27.9894 19.6826 28.0068 19.2759 28.0068 16.0186C28.0041 12.7626 28.0041 12.3493 27.9294 11.0733ZM15.9921 22.1359C12.5868 22.1359 9.82809 19.3773 9.82809 15.9719C9.82809 12.5666 12.5868 9.80794 15.9921 9.80794C17.6269 9.80794 19.1947 10.4574 20.3507 11.6133C21.5067 12.7693 22.1561 14.3371 22.1561 15.9719C22.1561 17.6067 21.5067 19.1746 20.3507 20.3305C19.1947 21.4865 17.6269 22.1359 15.9921 22.1359ZM22.4014 11.0173C21.6054 11.0173 20.9641 10.3746 20.9641 9.57994C20.9641 9.39128 21.0013 9.20446 21.0735 9.03015C21.1457 8.85585 21.2515 8.69747 21.3849 8.56407C21.5183 8.43066 21.6767 8.32483 21.851 8.25264C22.0253 8.18044 22.2121 8.14328 22.4008 8.14328C22.5894 8.14328 22.7762 8.18044 22.9506 8.25264C23.1249 8.32483 23.2832 8.43066 23.4166 8.56407C23.55 8.69747 23.6559 8.85585 23.7281 9.03015C23.8003 9.20446 23.8374 9.39128 23.8374 9.57994C23.8374 10.3746 23.1948 11.0173 22.4014 11.0173Z"
                      fill="#43B02A"
                    />
                    <path
                      d="M15.992 19.9758C18.2034 19.9758 19.996 18.1831 19.996 15.9718C19.996 13.7604 18.2034 11.9678 15.992 11.9678C13.7807 11.9678 11.988 13.7604 11.988 15.9718C11.988 18.1831 13.7807 19.9758 15.992 19.9758Z"
                      fill="#43B02A"
                    />
                  </svg>
                </a>
                <a
                  className="footer__social-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/lika.net.ua/"
                >
                  <span style={{ display: "none" }}>facebook</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.04 0H0.96C0.429 0 0 0.429 0 0.96V23.04C0 23.571 0.429 24 0.96 24H23.04C23.571 24 24 23.571 24 23.04V0.96C24 0.429 23.571 0 23.04 0ZM20.268 7.005H18.351C16.848 7.005 16.557 7.719 16.557 8.769V11.082H20.145L19.677 14.703H16.557V24H12.816V14.706H9.687V11.082H12.816V8.412C12.816 5.313 14.709 3.624 17.475 3.624C18.801 3.624 19.938 3.723 20.271 3.768V7.005H20.268Z"
                      fill="#43B02A"
                    />
                  </svg>
                </a>
                <a
                  className="footer__social-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/channel/UChJCfbehCQ2g6UNWmwZtykA"
                >
                  <span style={{ display: "none" }}>youtube</span>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4365 6.33114C26.2828 5.75871 25.9814 5.23669 25.5626 4.81732C25.1438 4.39795 24.6221 4.09594 24.0499 3.94152C21.9435 3.375 13.5 3.375 13.5 3.375C13.5 3.375 5.05647 3.375 2.95011 3.9385C2.37763 4.09243 1.85572 4.39427 1.4368 4.81371C1.01788 5.23316 0.716703 5.75545 0.563504 6.32812C-1.14952e-07 8.4375 0 12.8371 0 12.8371C0 12.8371 -1.14952e-07 17.2366 0.563504 19.343C0.873884 20.5061 1.78996 21.4222 2.95011 21.7326C5.05647 22.2991 13.5 22.2991 13.5 22.2991C13.5 22.2991 21.9435 22.2991 24.0499 21.7326C25.2131 21.4222 26.1261 20.5061 26.4365 19.343C27 17.2366 27 12.8371 27 12.8371C27 12.8371 27 8.4375 26.4365 6.33114ZM10.8181 16.875V8.79911L17.8092 12.8069L10.8181 16.875Z"
                      fill="#43B02A"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <button className="scroll-up-btn" onClick={scrollTop} name="scroll-up">
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" fill="white" />
              <path
                d="M25.6706 17.8358C25.5815 18.0132 25.4257 18.1481 25.2373 18.2108C25.0489 18.2735 24.8433 18.2589 24.6656 18.1703L16.0001 13.8398L7.33614 18.1718C7.248 18.2168 7.15181 18.244 7.05313 18.2516C6.95445 18.2593 6.85522 18.2474 6.76117 18.2165C6.66712 18.1857 6.58011 18.1365 6.50515 18.0719C6.43019 18.0072 6.36877 17.9284 6.32443 17.8399C6.28008 17.7514 6.25369 17.655 6.24678 17.5563C6.23987 17.4576 6.25257 17.3584 6.28415 17.2646C6.31572 17.1708 6.36556 17.0842 6.43078 17.0097C6.496 16.9353 6.57531 16.8745 6.66414 16.8308L15.6641 12.3308C15.7684 12.2785 15.8835 12.2513 16.0001 12.2513C16.1168 12.2513 16.2318 12.2785 16.3361 12.3308L25.3361 16.8308C25.5136 16.9199 25.6484 17.0758 25.7111 17.2642C25.7738 17.4526 25.7593 17.6581 25.6706 17.8358Z"
                fill="#43B02A"
              />
            </svg>
          </div>
        </button>
      </div>
      <div className="footer__bottom-section">
        <div className="container">
          <p className="footer__copyright">
            © 2008 - {newDate.getFullYear()}, Ліка Комфорт. Всі права захищено.
            <a href="https://redchameleon.com.ua/ua/podderzhka/dorabotka-sayta/" target="_blank" rel="noreferrer">Підтримка сайту</a>
            <a href="https://redchameleon.com.ua/ua/" target="_blank" rel="noreferrer">Red Chameleon</a>
          </p>
          <p className="footer__sitemap">
            <a href="/sitemap">Карта сайту</a>
          </p>
          <div className="footer__ico-box">
            <img src={masterCard} alt="мастеркард" />
            <img src={visa} alt="виза" />
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (store) => {
  return {
    phones: store.phoneReducer.phones,
    workTime: store.workTimeReducer.workTime,
    address: store.addressReducer.address,
    city: store.addressReducer.city
  };
};

export default connect(mapStateToProps)(Footer);
