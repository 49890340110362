import React from 'react'
import {Link} from "react-router-dom";
import HeaderBottomSearch from "./HeaderBottomSearch";
import HeaderCatalog from "./Categories/HeaderCatalog";

const HeaderBottom = (props) => {

    return (
        <div className="header__bottom">
            <div className="container">
                <HeaderCatalog categoryData={props.categoryData}/>
                <div className="header-about">
                    <Link className="header-about__item header-about__item--discount" to='/catalog_discount'>% Акції</Link>
                    <Link className="header-about__item" to='/catalog/82-rozprodaz'>Розпродаж</Link>
                    <Link className="header-about__item" to='/kondiciyuvannya-optom'>Гурт</Link>
                    <a className="header-about__item" href="https://www.likaimv.net.ua" target="_blank">Виїзд спеціаліста</a>
                    <Link className="header-about__item" to='/cases'>Наші роботи</Link>
                    <Link className="header-about__item" to='/about_us'>Про нас</Link>
                </div>
                <div className="header-selection"></div>
                <HeaderBottomSearch />
            </div>
        </div>
    )
}

export default HeaderBottom