import React, { useState, useEffect } from "react";
import {_images} from "../../../API";

const MainBannerSlide = ({ data }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <a className="main-sl-item" href={data.link_url}>
            <span style={{ display: "none" }}>Рекламная информация</span>
            <div className="main-sl-item__bg">
                <div className="main-sl-item__bg-left">
                    {!isMobile && (
                        data.video ? (
                            <div dangerouslySetInnerHTML={{ __html: `
                                <video
                                    muted
                                    autoplay
                                    loop
                                    playsinline
                                    src="${_images}${data.video}"
                                />
                            `}}/>
                        ) : (
                            <img src={`${_images}${data.image}`} alt="" />
                        )
                    )}
                </div>
                <div className="main-sl-item__bg-left mobile">
                    {isMobile && (
                        data.mobile_video ? (
                            <div dangerouslySetInnerHTML={{ __html: `
                                <video
                                    muted
                                    autoplay
                                    loop
                                    playsinline
                                    src="${_images}${data.mobile_video}"
                                />
                            `}}/>
                        ) : (
                            <img src={`${_images}${data.mobile_image}`} alt="" />
                        )
                    )}
                </div>
            </div>
        </a>
    )
}

export default MainBannerSlide